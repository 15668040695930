export const routes = {
    home: "/",
    login: "/auth/login",
    signup: "/auth/signup",
    forgot: "/auth/forgot-password",
    reset: "/auth/reset-password",
    dashboard: "/admin/dashboard",
    users: "/admin/users",
    posts: "/admin/posts",
    events: "/admin/events",
    communities: "/admin/communities",
    communityDetail: "/admin/communities/:id",
    poems: "/admin/poems",

    publications: "/publications",
    publication: "/publication",
    evenements: "/evenements",
    islam: "/islam",
    tijania: "/tariqas-cheikh-ahmad-tijani",
    liwaoulham: "/liwaoulhamd",
    ecrits: "/avis-du-cheikh",
    profile: "/profile",

    figures: "/figures-marquants",
    tariqas: "/tariqas",
    objectif: "/objectifs-de-la-voie",
    moukhadams: "/quelques-grands-moukhadams",
    histoire: "/histoire-et-biographie",
    definitions: "/definitions-des-conceptes",
    moukhadamsBaye: "/quelques-grands-moukhadams-de-baye",
    parcours: "/parcours-du-cheikh-aziz",
    activites: "/nos-activites",
    publicationsCheikh: "/publications-du-cheikh",
    sections: "/les-differents-sections-ou-kossi",
    contributions: "/contributions",
    poemes: "/poemes et ecrits de baye",
    institut: "/institut",
    ecole: "/ecole-coranique",
};
